import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ReviewRatingItem extends Component {
  render() {
    const { title, rating, comments } = this.props;
    return (
      <div className="list-group">
        <div className="list-group-item">
          <h5>{title}</h5>
          <p>
            Rating: {rating} of 5 {comments && <br />}
            {comments}
          </p>
        </div>
      </div>
    );
  }
}

export default ReviewRatingItem;

ReviewRatingItem.propTypes = {
  title: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired,
  comments: PropTypes.string,
};

ReviewRatingItem.defaultProps = {
  comments: null,
};
