import React, { Component } from 'react';
import Card from '../Card';

class ReviewCard extends Component {
  render() {
    return <Card {...this.props} style={{ clear: 'both' }} />;
  }
}

export default ReviewCard;
