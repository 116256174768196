/* eslint no-unused-expressions: 0 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Footer, SEO } from 'components';

const PureLayout = ({ children }) => (
  <>
    <SEO />
    <Header />
    {children}
    <Footer>
      <p>
        &copy; Rambeck Family. All rights reserved.<br />
        Book cover images provided by <a href="https://openlibrary.org/">Open Library</a>.
      </p>
    </Footer>
  </>
);

class Layout extends Component {
  render() {
    return <PureLayout {...this.props} />;
  }
}

export default Layout;

PureLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};
