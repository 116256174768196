import React, { Component } from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';

const types = {
  primary: ['border-light'],
  secondary: ['bg-light'],
};

class Card extends Component {
  render() {
    const { type, wrapperClass, header, contentHtml, children, style } = this.props;
    const wrapperClasses = className('card', 'mb-3', wrapperClass, types[type]);
    return (
      <div className={wrapperClasses} style={style}>
        <div className="card-header">{header}</div>
        <div className="card-body">
          {children && <div className="card-text">{children}</div>}
          {contentHtml && <div className="card-text" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
        </div>
      </div>
    );
  }
}

export default Card;

Card.propTypes = {
  type: PropTypes.string,
  wrapperClass: PropTypes.string,
  header: PropTypes.string.isRequired,
  contentHtml: PropTypes.string,
  children: PropTypes.node,
};

Card.defaultProps = {
  type: 'primary',
  wrapperClass: '',
  contentHtml: '',
  children: null,
};
