import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from '@emotion/styled/macro';
import { Layout, SEO } from 'components';
import { ReviewCard, ReviewRatingItem } from '../components/Review';
import website from '../../config/website';

const StyledImage = styled.img`
  margin: 0 0 0.5em 0.5em;
  width: 8rem;
  max-width: 40%;
`;

const Review = ({ data: { prismicReview, reviews }, location }) => {
  const { data } = prismicReview;
  return (
    <Layout>
      <SEO title={`${data.title} | ${website._title}`} pathname={location.pathname} article />
      <main id="main" className="container mt-4">
        <div className="review">
          <h1 className="review__title">{data.title}</h1>
          <StyledImage
            src={`https://covers.openlibrary.org/b/isbn/${data.isbn}-M.jpg`}
            alt={data.title}
            className="review__image float-right"
          />
          <h4 className="review__author">by {data.author}</h4>
          <p className="review__genre">Genre: {data.genre}</p>
          <p className="review__age">Age: {data.age}+</p>
          <p className="review__pages">{data.pages} pages</p>
          <p className="review__themes">Character Themes: {data.themes_text.text}</p>

          <ReviewCard wrapperClass="review__purchase" header="Purchase">
            <a href={`http://www.amazon.com/gp/product/${data.isbn}`} className="btn btn-primary">Amazon</a>
          </ReviewCard>

          <ReviewCard wrapperClass="review__synopsis" header="Synopsis" contentHtml={data.synopsis.html} />

          <ReviewCard wrapperClass="review__review" header="Review" contentHtml={data.review.html} />

          <ReviewCard wrapperClass="review__ratings" header="Parent's Guide">
            <ReviewRatingItem
              title="Violence"
              rating={data.rating_violence}
              comments={data.rating_violence_comments.text}
            />
            <ReviewRatingItem
              title="Romance"
              rating={data.rating_romance}
              comments={data.rating_romance_comments.text}
            />
            <ReviewRatingItem
              title="Mature Content"
              rating={data.rating_mature}
              comments={data.rating_mature_comments.text}
            />
            <ReviewRatingItem
              title="Language"
              rating={data.rating_language}
              comments={data.rating_language_comments.text}
            />
            <ReviewRatingItem
              title="Fantastical Elements"
              rating={data.rating_fantasy}
              comments={data.rating_fantasy_comments.text}
            />
          </ReviewCard>
        </div>
      </main>
    </Layout>
  );
};

export default Review;

Review.propTypes = {
  data: PropTypes.shape({
    prismicReview: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query ReviewBySlug($uid: String!) {
    prismicReview(uid: { eq: $uid }) {
      uid
      first_publication_date
      last_publication_date
      data {
        title
        date_updated(formatString: "DD.MM.YYYY")
        author
        genre
        age
        isbn
        pages
        themes_text {
          text
        }
        synopsis {
          html
        }
        review {
          html
        }
        rating_violence
        rating_violence_comments {
          text
        }
        rating_language
        rating_language_comments {
          text
        }
        rating_mature
        rating_mature_comments {
          text
        }
        rating_romance
        rating_romance_comments {
          text
        }
        rating_fantasy
        rating_fantasy_comments {
          text
        }
      }
    }
    reviews: allPrismicReview(limit: 2, sort: { fields: [data___date_updated], order: DESC }) {
      edges {
        node {
          uid
          data {
            title
            date_updated(formatString: "DD.MM.YYYY")
          }
        }
      }
    }
  }
`;
